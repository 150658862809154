import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";

export default function Cat(){
    const { catId } = useParams();
    const [q, setQ] = useState('');
    const [searchParam] = useState(['name', 'text']);
    const [sortOrder, setSortOrder] = useState('normal');
    
    const [data, setData] = useState([]);
    useEffect(() => {
        fetch(`/${catId}`)
        .then(res => res.json())
        .then(data => setData(data))
        .catch(err => console.log(err));
    }, [catId]);

    function Price(props){
        let nr = props.nr;
        let row = [];
        for (let i = 0; i < 5; i++){
            if(nr > 1){
                 row.push(<i class="yes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M48.1 240c-.1 2.7-.1 5.3-.1 8v16c0 2.7 0 5.3 .1 8H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H60.3C89.9 419.9 170 480 264 480h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264c-57.9 0-108.2-32.4-133.9-80H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H112.2c-.1-2.6-.2-5.3-.2-8V248c0-2.7 .1-5.4 .2-8H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H130.1c25.7-47.6 76-80 133.9-80h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264C170 32 89.9 92.1 60.3 176H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H48.1z"/></svg></i>)
            } else {
                row.push(<i ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M48.1 240c-.1 2.7-.1 5.3-.1 8v16c0 2.7 0 5.3 .1 8H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H60.3C89.9 419.9 170 480 264 480h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264c-57.9 0-108.2-32.4-133.9-80H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H112.2c-.1-2.6-.2-5.3-.2-8V248c0-2.7 .1-5.4 .2-8H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H130.1c25.7-47.6 76-80 133.9-80h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264C170 32 89.9 92.1 60.3 176H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H48.1z"/></svg></i>)
            }
            nr -= 1;
        }
        return row;
    }
    function search(data) {
        return data.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }
    function sort(order){
        const sortedData = [...data]; // Create a new array to avoid mutating the original
        if (order === 'asc') {
            setData(sortedData.sort((a, b) => a.price - b.price));
        } else if (order === 'desc') {
            setData(sortedData.sort((a, b) => b.price - a.price));
        } else if(order === 'normal'){
            setData(sortedData.sort((a, b) => a.id - b.id));
        }
    }
    const filteredData = search(data);
    return (        
    <div class="container-fluid">
    <div class="px-lg-5">
      <div class="row py-5">
            <div className="logo">
                <Link to="/"><img src="/logo.png" alt="Dekoartikel" /><p>Zurück zur Startseite</p></Link>
            
            </div> 
        <div class="col-lg-12 mx-auto">
          <div class="text-muted p-5 shadow-sm rounded banner">
                <h1>{catId}</h1>
          </div>
        </div>
      </div>
      <div class="input-group-cont">
        <div class="input-group flex-nowrap">
            <span class="input-group-text" id="addon-wrapping">🔎</span>
            <input onChange={(e)=> setQ(e.target.value)} type="text" class="form-control" placeholder="Suche..." value={q} aria-label="search" aria-describedby="addon-wrapping"  />
        </div>
        <p onClick={() => {
            const newOrder = sortOrder === 'asc' ? 'desc' : sortOrder === 'normal' ? 'asc': 'normal';
            setSortOrder(newOrder);
            sort(newOrder);
        }} className="price">
            Preis <span className={sortOrder==='normal'? 'none': sortOrder==='desc' ? 'desc' : 'asc'}>⬆️</span>
        </p>
       </div>
  
      <div class="row">
                    {filteredData.map((item, i) => (
                        <div class="item col-xl-3 col-lg-4 col-md-6 mb-4" key={item.name}>
                        <a href={item.link} target="_blank" class="text-decoration-none" rel="noreferrer">
                            <div class="bg-white rounded shadow-sm"><img src={item.img} alt={item.name} class="img-fluid card-img-top" />
                                <div class="euro">
                                <Price nr={item.price} />
                                </div>
                                <div class="p-4">
                                <h5 class="text-dark">{item.name}</h5>
                                <p class="small text-muted mb-0">{item.text}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    ))}
                </div>
        </div>
    </div>
    )
}
