import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Homepage from './pages/Homepage';
import NotFound from './pages/NotFoundPage';
import Cat from './pages/CatPage';

import'./index.css';

const router = createBrowserRouter([{
  path: '/',
  element: <Homepage />,
  errorElement: <NotFound />
},
{
  path: '/:catId',
  element: <Cat />
}]);

function App(){
  return (
    <RouterProvider router={router} />
  )
}

export default App