import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Homepage() {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch('/categories')
    .then(res => res.json())
    .then(data => setData(data))
    .catch(err => console.log(err));
  }, [])
  return (
    <div class="container-fluid">
      <div className="logo">
        <img src="/logo.png" alt="Dekoartikel" />
      </div> 
        <div class="px-lg-5"> 
          <div class="row py-5">
            <div class="col-lg-12 mx-auto">
              <div class="text-muted p-5 shadow-sm rounded banner">
                <h1 class="display-4">Dekoartikel und anderes</h1>
              </div>
            </div>
          </div>
          <div class="row">
          {data.map((cat, i) => (
            <div class="item col-xl-3 col-lg-4 col-md-6 mb-4">
                <Link key={cat.name} to={`/${cat.name}`} class="text-decoration-none">
                    <div class="bg-white rounded shadow-sm">
                        <img src={cat.img} alt={cat.name} class="img-fluid card-img-top" />
                     
                        <div class="p-4">
                        <h5 class="text-dark">{cat.name}</h5>
                        {(()=>{
                          if (cat.text !== ""){
                            return <p class="small text-muted mb-0">cat.text</p>
                          }
                        })
                        }
                        </div>
                    </div>
                </Link>
            </div>
          ))}
          </div>
        </div>
      </div>
  )
}

export default Homepage